import React from 'react';
import styled from 'styled-components/macro';
import { useAuth } from '../components/auth/index';
import { Table, booleanCellRenderer } from '../components/table';

const Container = styled.div`
  display: flex;
  gap: 1rem;
  text-align: left;
`;

export function Profile() {
  const { status } = useAuth();

  return (
    <Container>
      <Table
        columnDef={[
          { field: 'module', filter: 'agTextColumnFilter' },
          { field: 'allowed.GET', headerName: 'Read', cellRenderer: booleanCellRenderer },
          {
            field: 'allowed.POST',
            headerName: 'Create',
            cellRenderer: booleanCellRenderer,
          },
          { field: 'allowed.PUT', headerName: 'Edit', cellRenderer: booleanCellRenderer },
          {
            field: 'allowed.DELETE',
            headerName: 'Delete',
            cellRenderer: booleanCellRenderer,
          },
        ]}
        rowData={status.permissions}
        height={'90vh'}
      />
    </Container>
  );
}
