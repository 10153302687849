import { createContext, useEffect, useState } from 'react';
import { useContext } from 'react';
import { HTTP } from '../../lib/http';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { sendErrorToast } from '../../lib/toast';

export const PERMISSIONS = {
  'web.map': 'web.map',

  'webevent.log': 'webevent.log',

  'webapi.Map': 'webapi.Map',
  'webapi.Animal': 'webapi.Animal',
  'webapi.Command': 'webapi.Command',
  'webapi.Hostile': 'webapi.Hostile',
  'webapi.Log': 'webapi.Log',
  'webapi.Player': 'webapi.Player',
  'webapi.RegisterUser': 'webapi.RegisterUser',
  'webapi.ServerInfo': 'webapi.ServerInfo',
  'webapi.ServerStats': 'webapi.ServerStats',
  'webapi.Mods': 'webapi.Mods',
  'webapi.WebUiUpdates': 'webapi.WebUiUpdates',
  'webapi.viewallclaims': 'webapi.viewallclaims',
  'webapi.viewallplayers': 'webapi.viewallplayers',
  'webapi.SteamLoginApi': 'webapi.SteamLoginApi',
  'webapi.ExecuteConsoleCommand': 'webapi.ExecuteConsoleCommand',
  'webapi.GetAllowedCommands': 'webapi.GetAllowedCommands',
  'webapi.GetAnimalsLocation': 'webapi.GetAnimalsLocation',
  'webapi.GetHostileLocation': 'webapi.GetHostileLocation',
  'webapi.GetLandClaims': 'webapi.GetLandClaims',
  'webapi.GetLog': 'webapi.GetLog',
  'webapi.GetPlayerInventories': 'webapi.GetPlayerInventories',
  'webapi.GetPlayerInventory': 'webapi.GetPlayerInventory',
  'webapi.GetPlayerList': 'webapi.GetPlayerList',
  'webapi.GetPlayersLocation': 'webapi.GetPlayersLocation',
  'webapi.GetPlayersOnline': 'webapi.GetPlayersOnline',
  'webapi.GetServerInfo': 'webapi.GetServerInfo',
  'webapi.GetStats': 'webapi.GetStats',
  'webapi.GetWebUIUpdates': 'webapi.GetWebUIUpdates',
  'webapi.OpenAPI': 'webapi.OpenAPI',
  'webapi.Bloodmoon': 'webapi.Bloodmoon',
  'webapi.GamePrefs': 'webapi.GamePrefs',
  'webapi.GameStats': 'webapi.GameStats',
  'webapi.Blacklist': 'webapi.Blacklist',
  'webapi.CommandPermissions': 'webapi.CommandPermissions',
  'webapi.UserPermissions': 'webapi.UserPermissions',
  'webapi.WebApiTokens': 'webapi.WebApiTokens',
  'webapi.WebModules': 'webapi.WebModules',
  'webapi.WebUsers': 'webapi.WebUsers',
  'webapi.Whitelist': 'webapi.Whitelist',
  'webapi.Item': 'webapi.Item',
  'webapi.Markers': 'webapi.Markers',
};

export const AuthContext = createContext({ status: { loggedin: false } });

export function AuthProvider({ children }) {
  const [loggedIn, setLoggedIn] = useState(false);

  const { data, isLoading, refetch, isError, isFetched } = useQuery('userstatus', {
    queryFn: () => HTTP.get('/userstatus'),
    refetchOnWindowFocus: 'always',
    onSettled: (data) => {
      setLoggedIn(data.loggedIn);
    },
  });

  useEffect(() => {
    if (!loggedIn && isFetched) {
      sendErrorToast('Not logged in');
    }
  }, [loggedIn]);

  function checkPermission(requestedPermissions) {
    const permissionsToCheck = Array.isArray(requestedPermissions) ? requestedPermissions : [requestedPermissions];
    for (let i = 0; i < permissionsToCheck.length; i++) {
      const mod = data.permissions.find((p) => p.module.toLowerCase() === permissionsToCheck[i].module.toLowerCase());
      if (!mod || !mod.allowed[permissionsToCheck[i].method]) {
        return false;
      }
    }

    return true;
  }

  if (isLoading || !data || isError) return null;

  return (
    <AuthContext.Provider
      value={{
        status: data,
        loading: isLoading,
        permissions: data.permissions,
        checkPermission,
        refetch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAuth() {
  return useContext(AuthContext);
}
