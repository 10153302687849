import React, { createContext, createElement, useEffect, useState, useContext } from 'react';
import styled from 'styled-components/macro';
import { HTTP } from './http';
import { Table, EditableTable } from '../components/table';
import PropTypes from 'prop-types';

import * as FormElements from '../components/settings/forms/style';
import { TfpForm } from '../components/settings/forms/base';
import { useForm } from 'react-hook-form';

import { useQuery, useMutation } from 'react-query';

import { useExternalScripts } from './useExternalScripts';

export const ModsContext = createContext({});

export function ModsProvider({ children }) {
  const [apiMods, setApiMods] = useState(null);

  useEffect(() => {
    async function getMods() {
      const mods = await HTTP.getMods();
      setApiMods(mods);
    }
    getMods();
  }, []);

  let { webMods, loading } = useExternalScripts(apiMods);

  if (webMods && !loading) {
    if (webMods.length !== apiMods.filter((mod) => mod.web).length) {
      console.error('Mods length mismatch, are all IDs mapped correctly between js mods and backend response?', webMods, apiMods);
    }
  }

  const modRoutes = [];

  if (webMods) {
    webMods.forEach((mod) => {
      for (const route in mod.external.routes) {
        if (Object.hasOwnProperty.call(mod.external.routes, route)) {
          const element = mod.external.routes[route];
          const sanitizedName = mod.name.toLowerCase().split(' ').join('');
          modRoutes.push({
            path: `mods/${sanitizedName}/${route}`,
            name: route,
            element: hydrateModComponent(element, { React }),
          });
        }
      }
    });
  }

  if (!webMods) {
    webMods = [];
  }

  return <ModsContext.Provider value={{ mods: apiMods, webMods, modRoutes }}>{children}</ModsContext.Provider>;
}

ModsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useMods() {
  return useContext(ModsContext);
}

/**
 * Add references to the main React application inside mod components
 */
export function hydrateModComponent(component, extra = {}) {
  return createElement(component, {
    React,
    styled,
    HTTP,
    Table,
    EditableTable,
    FormElements,
    TfpForm,
    useForm,
    useQuery,
    useMutation,
    ...extra,
  });
}
