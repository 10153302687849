import styled from 'styled-components/macro';
import { useState } from 'react';
import { Button } from '../button';
import PropTypes from 'prop-types';

const FormContainer = styled.form`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledInput = styled.input`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  outline: none;
`;

export function LoginForm({ onSubmit }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  function onChangeUserName(e) {
    setUsername(e.target.value);
  }

  function onChangePassword(e) {
    setPassword(e.target.value);
  }

  return (
    <FormContainer onSubmit={(e) => onSubmit(e, username, password)}>
      <label htmlFor="username">Username</label>
      <StyledInput id="username" type="text" value={username} onChange={onChangeUserName} />
      <label htmlFor="password">Password</label>
      <StyledInput id="password" type="password" value={password} onChange={onChangePassword} />
      <Button text={'Submit'} onClick={(e) => onSubmit(e, username, password)} />
      <input type="submit" hidden />
    </FormContainer>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
