import { useAuth } from '../auth/index';
import { useState } from 'react';
import { LoginModal } from '../auth/loginModal';
import { SidebarNavLink } from './index';
import { FiLogIn, FiLogOut } from 'react-icons/fi';
import PropTypes from 'prop-types';

export function AuthLink({ collapsed }) {
  const { status } = useAuth();
  const [modalIsOpen, setIsModalOpen] = useState(false);

  const openComponent = <SidebarNavLink collapsed={collapsed} icon={<FiLogIn />} title={'Log in'} to="#" />;

  if (!status.loggedIn) {
    return <LoginModal modalIsOpen={modalIsOpen} setIsModalOpen={setIsModalOpen} OpenComponent={openComponent} />;
  }

  return <SidebarNavLink collapsed={collapsed} icon={<FiLogOut />} title={'Log out'} to="../session/logout" external={true} />;
}

AuthLink.propTypes = {
  collapsed: PropTypes.bool.isRequired,
};
