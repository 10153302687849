import styled from 'styled-components/macro';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: start;

  width: 100%;

  margin-bottom: 1rem;
`;

export const StyledFormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormButton = styled.button`
  display: block;
  width: auto;
  height: 3rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: ${(props) => (props.isError ? props.theme.colors.tfpRed : props.theme.colors.tfpBlue)};
  color: ${(props) => props.theme.colors.tfpWhite};
  border: none;
  border-radius: 5px;

  align-self: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.tfpLightGrey};
    color: ${(props) => props.theme.colors.tfpWhite};
  }

  &:active {
    transform: translateY(1px);
  }
`;

export const FormLabel = styled.label`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

export const FormInput = styled.input`
  padding: 0.75rem;
  font-size: 1rem;
  border: 2px solid ${(props) => props.theme.colors.tfpDarkGrey80};
  background-color: ${(props) => props.theme.colors.tfpMediumGrey};
  border-radius: 5px;
  color: ${(props) => props.theme.colors.text};

  &:focus {
    border-color: ${(props) => props.theme.colors.tfpBlue};
    outline: none;
  }
`;

export const FormSelect = styled.select`
  padding: 0.75rem;
  font-size: 1rem;
  border: 2px solid ${(props) => props.theme.colors.tfpDarkGrey80};
  background-color: ${(props) => props.theme.colors.tfpMediumGrey};
  border-radius: 5px;
  color: ${(props) => props.theme.colors.text};

  &:focus {
    border-color: ${(props) => props.theme.colors.tfpBlue};
    outline: none;
  }
`;

export const FormOption = styled.option`
  padding: 0.75rem;
  font-size: 1rem;
  border: 2px solid ${(props) => props.theme.colors.tfpDarkGrey80};
  background-color: ${(props) => props.theme.colors.tfpMediumGrey};
  border-radius: 5px;
  color: ${(props) => props.theme.colors.text};

  &:focus {
    border-color: ${(props) => props.theme.colors.tfpBlue};
    outline: none;
  }
`;

export const FormError = styled.span`
  color: ${(props) => props.theme.colors.tfpRed};
  font-size: 0.8rem;
  margin-top: 0.5rem;
`;

export const FormErrorList = styled.div`
  display: flex;
  flex-direction: column;
`;

// This will contain the button and any errors
export const FormTail = styled.div`
  height: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;
