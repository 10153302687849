import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { CollapseIcon } from '../collapseIndicator';
import useLocalStorageState from 'use-local-storage-state';

const StyledTableCellContainer = styled.div`
  cursor: ${(props) => (props.canCollapse ? 'pointer' : 'default')};
`;

const StyledTableCellLine = styled.div`
  line-height: 1.1rem;
  white-space: pre;
`;

const COLLAPSED_LENGTH = 150;

function shouldCollapseByDefault(text) {
  if (!text) return false;
  return text.length > COLLAPSED_LENGTH;
}

export function ConsoleHelpTableCell({ text }) {
  const canCollapse = shouldCollapseByDefault(text);
  const [collapsed, setCollapsed] = useState(canCollapse);
  const [command, setCommand] = useLocalStorageState('command', {
    defaultValue: '',
  });

  if (!text) return '';

  const split = text.toString().split('\n');

  function onClick(e) {
    switch (e.detail) {
      case 1: {
        // Click
        const selection = window.getSelection();
        if (selection.type !== 'Range') {
          setCollapsed(!collapsed);
        }
        break;
      }
      case 2:
        // Double click
        if (!canCollapse) {
          if (command[command.length - 1] === ' ') {
            setCommand(`${command}${e.target.textContent}`);
          } else if (!command.length) {
            setCommand(e.target.textContent);
          } else {
            setCommand(`${command} ${e.target.textContent}`);
          }
        }
        break;
      default:
        break;
    }
  }

  if (collapsed) {
    return (
      <StyledTableCellContainer onClick={onClick} canCollapse={canCollapse}>
        <CollapseIcon canCollapse={canCollapse} collapsed={collapsed} />
        <StyledTableCellLine>{split.join(' ').slice(0, COLLAPSED_LENGTH)}</StyledTableCellLine>
      </StyledTableCellContainer>
    );
  }

  return (
    <StyledTableCellContainer onClick={onClick} canCollapse={canCollapse}>
      <CollapseIcon canCollapse={canCollapse} collapsed={collapsed} />
      {split.map((line, index) => {
        return <StyledTableCellLine key={index}>{line}</StyledTableCellLine>;
      })}
    </StyledTableCellContainer>
  );
}

ConsoleHelpTableCell.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
