import { LayerGroup, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { HTTP } from '../../lib/http';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import playerIcon from '../../img/map/icons/ui_game_symbol_player.png';
import { useMods } from '../../lib/mods';

function Player({ lat, lng, name }) {
  const { webMods } = useMods();

  const iconOverride = webMods.find((mod) => mod.external.iconOverrides && mod.external.iconOverrides.player);

  const icon = L.icon({
    iconUrl: iconOverride ? iconOverride.external.iconOverrides.player : playerIcon,
    iconSize: [32, 32],
  });
  return (
    <Marker position={[lat, lng]} icon={icon}>
      <Popup>{name}</Popup>
    </Marker>
  );
}

Player.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export function PlayerLayer() {
  const [players, setPlayers] = useState([]);

  async function loadPlayers() {
    const players = await HTTP.getPlayers();
    setPlayers(players);
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      loadPlayers();
    }, 10000);

    loadPlayers();
    return () => clearInterval(interval);
  }, []);

  const allMarkers = players.map((a) => <Player key={a.id} lat={a.position.x} lng={a.position.z} name={a.name}></Player>);

  return <LayerGroup name={'players'}>{allMarkers}</LayerGroup>;
}
