import React from 'react';
import { useQuery } from 'react-query';
import { HTTP } from '../../lib/http';
import { EditableTable } from '../table';
import { FormAndTableContainer } from './style';

export function WebUsers() {
  const { isLoading, data, refetch } = useQuery('webUsers', () => HTTP.get('/api/webusers'));

  return (
    <FormAndTableContainer>
      <p>
        To create a new user, execute the <code>createwebuser</code> command in game.
      </p>
      <EditableTable
        columnDef={[
          { field: 'platformUserId.combinedString', filter: 'agTextColumnFilter', headerName: 'Platform User ID' },
          { field: 'crossplatformUserId.combinedString', filter: 'agTextColumnFilter', headerName: 'Crossplatform User ID' },
          { field: 'name', filter: 'agTextColumnFilter', sort: 'asc' },
        ]}
        rowData={data}
        loading={isLoading}
        reloadFn={refetch}
      />
    </FormAndTableContainer>
  );
}
