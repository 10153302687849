import styled from 'styled-components/macro';
import { Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { NotFound } from './pages/404';
import { Settings } from './pages/Settings';
import { Error } from './pages/Error';
import { Register } from './pages/Register';
import { Console } from './pages/Console';
import { Mods } from './pages/Mods';
import { Map } from './pages/Map';

import { useMods, hydrateModComponent } from './lib/mods';
import { Profile } from './pages/Profile';
import { Empty } from './pages/Empty';

import { WebUsers } from './components/settings/webUsers';
import { UserPermissions } from './components/settings/userPermissions';
import { WebPermissions } from './components/settings/webPermissions';
import { ApiTokens } from './components/settings/apiTokens';
import { Bans } from './components/settings/bans';
import { AllowList } from './components/settings/allowList';
import { CommandPermissions } from './components/settings/commandPermissions';
import { GroupPermissions } from './components/settings/groupPermissions';
import { useAuth } from './components/auth';

const ContentContainer = styled.div`
  min-width: ${(props) => (props.collapsed ? 'calc(100% - 100px)' : 'calc(100% - 250px)')};
  padding: 1%;
`;

export function TFPRouter({ sidebarCollapsed }) {
  const { modRoutes, webMods } = useMods();
  const { checkPermission } = useAuth();

  const modSettings = [];

  if (webMods) {
    webMods.forEach((mod) => {
      for (const route in mod.external.settings) {
        if (Object.hasOwnProperty.call(mod.external.settings, route)) {
          const element = mod.external.settings[route];
          modSettings.push({
            title: route,
            component: hydrateModComponent(element, { checkPermission }),
          });
        }
      }
    });
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ContentContainer collapsed={sidebarCollapsed}>
            <Empty />
          </ContentContainer>
        }
      />
      <Route
        path="/map"
        element={
          <ContentContainer collapsed={sidebarCollapsed}>
            <Map />
          </ContentContainer>
        }
      />
      <Route
        path="mods"
        element={
          <ContentContainer collapsed={sidebarCollapsed}>
            <Mods />
          </ContentContainer>
        }
      />
      <Route
        path="console"
        element={
          <ContentContainer collapsed={sidebarCollapsed}>
            <Console />
          </ContentContainer>
        }
      />

      <Route path="settings" element={<Settings />}>
        <Route path="web-users" element={<WebUsers />} />
        <Route path="user-permissions" element={<UserPermissions />} />
        <Route path="group-permissions" element={<GroupPermissions />} />
        <Route path="web-permissions" element={<WebPermissions />} />
        <Route path="command-permissions" element={<CommandPermissions />} />
        <Route path="api-tokens" element={<ApiTokens />} />
        <Route path="bans" element={<Bans />} />
        <Route path="allow-list" element={<AllowList />} />
        {modSettings.map((mod) => {
          return <Route key={mod.title} path={mod.title.split(' ').join('-')} element={mod.component} />;
        })}
      </Route>

      <Route
        path="profile"
        element={
          <ContentContainer collapsed={sidebarCollapsed}>
            <Profile />
          </ContentContainer>
        }
      />

      <Route path="createuser" element={<Register />} />

      {modRoutes.map((route) => {
        return (
          <Route
            path={route.path}
            element={<ContentContainer collapsed={sidebarCollapsed}>{route.element}</ContentContainer>}
            key={route.path}
          />
        );
      })}

      <Route path="error/:code" element={<Error />} />

      <Route
        path="*"
        element={
          <ContentContainer collapsed={sidebarCollapsed}>
            <NotFound />
          </ContentContainer>
        }
      />
    </Routes>
  );
}

TFPRouter.propTypes = {
  sidebarCollapsed: PropTypes.bool.isRequired,
};
