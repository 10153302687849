import 'leaflet/dist/leaflet.css';
import styled, { ThemeProvider } from 'styled-components/macro';
import { ModsProvider } from './lib/mods';
import './App.css';
import { SideBar } from './components/sidebar';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';

import backgroundImage from './img/bg-forest.jpg';
import { AuthProvider } from './components/auth';
import { TFPRouter } from './Router';
import useLocalStorageState from 'use-local-storage-state';

const theme = {
  colors: {
    transparent: 'transparent',
    tfpRed: '#ff0000',
    tfpBlue: '#00A3E0',
    tfpLightGrey: '#CCCCCC',
    tfpDarkGrey: '#828383',
    tfpDarkGrey20: 'rgba(0,0,0, 0.2)',
    tfpDarkGrey40: 'rgba(0,0,0, 0.4)',
    tfpDarkGrey60: 'rgba(0,0,0, 0.6)',
    tfpDarkGrey80: 'rgba(0,0,0, 0.8)',
    tfpMediumGrey: '#C4C4C4',
    tfpBlack: '#090C0C',
    tfpWhite: '#FFFFFF',
  },
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.tfpBlack};
  background-image: url(${backgroundImage});
  background-size: cover;
  color: ${(props) => props.theme.colors.tfpWhite};
`;

function App() {
  const [sidebarCollapsed, setSidebarCollapsed] = useLocalStorageState('sideBarCollapsed', {
    defaultValue: false,
  });

  const queryClient = new QueryClient();

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ModsProvider>
            <ThemeProvider theme={theme}>
              <Toaster position="bottom-left" reverseOrder={true} />
              <PageContainer>
                <SideBar collapsed={sidebarCollapsed} setCollapsed={setSidebarCollapsed} />
                <TFPRouter sidebarCollapsed={sidebarCollapsed} />
              </PageContainer>
            </ThemeProvider>
          </ModsProvider>
        </AuthProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
