import { EditableTable } from '../table';
import { useMutation, useQuery } from 'react-query';
import { StyledFormItem, FormLabel, FormInput, FormSelect, FormOption } from './forms/style';
import { TfpForm } from './forms/base';
import { useForm } from 'react-hook-form';
import { FormAndTableContainer } from './style';
import { HTTP } from '../../lib/http';
import { PERMISSIONS, useAuth } from '../auth';

export function AllowList() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const { checkPermission } = useAuth();
  const canEditRows = checkPermission({ module: PERMISSIONS['webapi.Whitelist'], method: 'POST' });
  const canDeleteRows = checkPermission({ module: PERMISSIONS['webapi.Whitelist'], method: 'DELETE' });
  const type = watch('type');

  const columnDefs = [
    { field: 'id', filter: 'agTextColumnFilter', headerName: 'ID' },
    { field: 'name', filter: 'agTextColumnFilter', headerName: 'Name', flex: 2, sort: 'asc' },
    { field: 'type', filter: 'agTextColumnFilter', headerName: 'Type', flex: 0.5 },
  ];

  const fetchWhitelist = async () => {
    const data = await HTTP.get('/api/whitelist');
    return [
      ...data.users.map((user) => ({ id: user.userId.combinedString, name: user.name, type: 'User' })),
      ...data.groups.map((group) => ({ id: group.groupId, name: group.name, type: 'Group' })),
    ];
  };

  const { data: whitelist, isLoading, refetch } = useQuery('whitelist', fetchWhitelist);

  const { mutate } = useMutation(
    (data) => {
      if (data.type === 'User') {
        return HTTP.post(`/api/whitelist/user/${data.id}`, { name: data.name });
      } else {
        return HTTP.post(`/api/whitelist/group/${data.id}`, { name: data.name });
      }
    },
    {
      onSettled: () => {
        reset();
        refetch();
      },
    }
  );

  const { mutate: delMutate } = useMutation(
    'deleteWhitelist',
    (data) => {
      if (data.type === 'User') {
        return HTTP.delete(`/api/whitelist/user/${data.id}`);
      } else {
        return HTTP.delete(`/api/whitelist/group/${data.id}`);
      }
    },
    {
      onSettled: () => {
        refetch();
      },
    }
  );

  const handleEdit = (data) => {
    mutate(data);
  };

  const handleDelete = (data) => {
    delMutate(data);
  };

  const setDefaultValues = (data) => {
    setValue('type', data.type);
    setValue('name', data.name);
    setValue('id', data.id);
  };

  const Form = (
    <TfpForm isLoading={isLoading} errors={errors}>
      <StyledFormItem>
        <FormLabel>Type</FormLabel>
        <FormSelect {...register('type')}>
          <FormOption value="User">User</FormOption>
          <FormOption value="Group">Group</FormOption>
        </FormSelect>
      </StyledFormItem>
      <StyledFormItem>
        <FormLabel>{type === 'User' ? 'User ID' : 'Group ID'}</FormLabel>
        <FormInput {...register('id')} />
      </StyledFormItem>
      <StyledFormItem>
        <FormLabel>Name</FormLabel>
        <FormInput {...register('name')} />
      </StyledFormItem>
    </TfpForm>
  );

  return (
    <FormAndTableContainer>
      <EditableTable
        columnDef={columnDefs}
        rowData={whitelist}
        loading={isLoading}
        canDeleteRows={canDeleteRows}
        deleteRowFn={handleDelete}
        editRowFn={handleSubmit(handleEdit)}
        editForm={Form}
        createRowFn={handleSubmit(handleEdit)}
        setDefaultValues={setDefaultValues}
        canEditRows={canEditRows}
        canCreateRows={canEditRows}
      />
    </FormAndTableContainer>
  );
}
