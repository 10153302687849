import { Dialog, DialogTrigger, DialogContent, DialogHeading, DialogClose } from '../dialog/dialog';
import { Button } from '../button';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledEditDialog = styled(Dialog)``;

const StyledModalContent = styled(DialogContent)`
  background-color: ${(props) => props.theme.colors.tfpBlack};
  color: ${(props) => props.theme.colors.tfpWhite};
  align-items: center;

  // Make the dialog take up the full height of the screen
  height: 100vh;
  width: 25vw;
  // Stick to right side of screen
  position: fixed;
  right: 0;
  // Space out header content and footer
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;

  & > *:first-child {
    flex-grow: 1;
    background-color: ${(props) => props.theme.colors.tfpBlue};
  }
`;

FormDialog.propTypes = {
  mutateFn: PropTypes.func.isRequired,
  form: PropTypes.element.isRequired,
  data: PropTypes.object.isRequired,
  setDefaultValues: PropTypes.func.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string,
};

export function FormDialog({ mutateFn, data, form, setDefaultValues, icon, title }) {
  return (
    <StyledEditDialog>
      <DialogTrigger onOpen={() => setDefaultValues(data)}>{icon}</DialogTrigger>
      <StyledModalContent>
        <DialogHeading>{title}</DialogHeading>
        {form}
        <Row>
          <Button
            text="Save"
            type="submit"
            onClick={() => {
              mutateFn();
            }}
          />
          <DialogClose>Close</DialogClose>
        </Row>
      </StyledModalContent>
    </StyledEditDialog>
  );
}
