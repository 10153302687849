import styled from 'styled-components/macro';
import { ConsoleWindow } from '../components/console/consoleWindow';
import { ConsoleHelp } from '../components/console/helpWindow';
import { ConsoleInput } from '../components/console/input';
import { useEffect } from 'react';
import { limitArrayLength } from '../lib/limitArray';
import { HTTP } from '../lib/http';
import { PERMISSIONS, useAuth } from '../components/auth';
import useLocalStorageState from 'use-local-storage-state';

const ConsoleContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export function Console() {
  const [executedCommands, setExecutedCommands] = useLocalStorageState('consoleExecutedCommands', {
    defaultValue: [],
  });
  const [messages, setMessages] = useLocalStorageState('consoleMessages', {
    defaultValue: [],
  });
  const [sseActive, setSseActive] = useLocalStorageState('sseActive', {
    defaultValue: true,
  });
  const [helpActive, setHelpActive] = useLocalStorageState('helpActiveState', {
    defaultValue: false,
  });
  const { checkPermission } = useAuth();

  function addMessage(message, startCollapsed = true) {
    const { type, msg } = message;
    message.timestamp = message.isotime ? new Date(message.isotime) : new Date();
    if (msg === '' && !msg.input) return;

    if (type === 'executedCommand') {
      setExecutedCommands((executedCommands) => limitArrayLength([...executedCommands, message], 100));
    }

    setMessages((messages) => limitArrayLength([...messages, { ...message, startCollapsed }], 500));
  }

  useEffect(() => {
    if (!checkPermission({ module: PERMISSIONS['webapi.Log'], method: 'GET' })) {
      setMessages([]);
      setHelpActive(true);
    }
  }, []);

  useEffect(() => {
    async function getInitialLines() {
      if (!checkPermission({ module: PERMISSIONS['webapi.Log'], method: 'GET' })) {
        return;
      }

      const existingLogLines = await HTTP.getLatestLogLines(-100);
      const logLines = existingLogLines.entries.map((entry) => ({
        timestamp: new Date(entry.isotime),
        ...entry,
      }));
      setMessages((messages) =>
        [...executedCommands.map((e) => ({ ...e, timestamp: new Date(e.timestamp) })), ...logLines].sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        )
      );
    }
    if (sseActive) {
      getInitialLines();
    }
  }, [sseActive]);

  useEffect(() => {
    if (!sseActive) {
      return;
    }

    if (!checkPermission({ module: PERMISSIONS['webevent.log'], method: 'GET' })) {
      return;
    }

    const eventSource = new EventSource('/sse/?events=log', { withCredentials: true });

    eventSource.addEventListener('logLine', (event) => {
      const data = JSON.parse(event.data);
      addMessage(data);
    });

    eventSource.addEventListener('error', (event) => {
      if (event.readyState === EventSource.CLOSED) {
        console.log('Connection was closed');
      }
    });

    eventSource.addEventListener('open', (e) => {
      console.log('Console: SSE opened');
    });

    return () => {
      eventSource.close();
    };
  }, [sseActive]);

  return (
    <ConsoleContainer>
      {helpActive ? <ConsoleHelp executedCommands={executedCommands} /> : <ConsoleWindow messages={messages} />}
      <ConsoleInput helpActive={helpActive} addMessage={addMessage} sseActive={sseActive} setSseActive={setSseActive} />
    </ConsoleContainer>
  );
}
