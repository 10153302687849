import { useEffect, useState } from 'react';

export const useExternalScripts = (mods) => {
  const [loading, setLoading] = useState(true);
  const [loadedResources, setLoadedResources] = useState(0);
  const [loadedModsWithData, setLoadedModsWithData] = useState([]);

  const handleResourceLoad = (e) => {
    if (e.type === 'load') {
      setLoadedResources((l) => l + 1);
    }

    if (e.type === 'error') {
      console.error(`Failed to load ${e.target.href || e.target.src}`);
      setLoadedResources((l) => l + 1);
    }
  };

  useEffect(() => {
    if (!mods) return;

    const modsWithWeb = mods.filter((mod) => mod.web);

    for (const url of modsWithWeb.map((mod) => mod.web.bundle)) {
      if (!url) continue;
      const script = document.querySelector(`script[src="${url}"]`);

      if (!script) {
        const element = document.createElement('script');
        element.type = 'text/javascript';
        element.src = url;
        element.async = true;
        document.body.appendChild(element);
        element.addEventListener('load', handleResourceLoad);
        element.addEventListener('error', handleResourceLoad);
      }
    }

    for (const url of modsWithWeb.map((mod) => mod.web.css)) {
      if (!url) continue;
      const style = document.querySelector(`link[href="${url}"]`);

      if (!style) {
        const element = document.createElement('link');
        element.rel = 'stylesheet';
        element.type = 'text/css';
        element.href = url;
        document.head.appendChild(element);
        element.addEventListener('load', handleResourceLoad);
        element.addEventListener('error', handleResourceLoad);
      }
    }
  }, [mods]);

  useEffect(() => {
    if (!mods) return;
    const modsWithWeb = mods.filter((mod) => mod.web);

    const totalResources = modsWithWeb.reduce((acc, mod) => {
      if (mod.web.bundle) acc += 1;
      if (mod.web.css) acc += 1;
      return acc;
    }, 0);

    const isEverythingAlreadyLoaded = loadedResources === totalResources;
    if (isEverythingAlreadyLoaded) {
      const loadedMods = modsWithWeb
        .filter((mod) => window[mod.name])
        .map((mod) => {
          return { ...mod, external: window[mod.name] };
        });
      setLoadedModsWithData(loadedMods);
      setLoading(false);
    }
  }, [mods, loadedResources]);

  if (loading) {
    return { loading, loadedResources };
  }

  return {
    loading,
    webMods: loadedModsWithData,
  };
};
