import Autosuggest from 'react-autosuggest';
import styled from 'styled-components/macro';
import { useTheme } from 'styled-components/macro';
import { useState } from 'react';
import { useEffect } from 'react';
import { sendErrorToast } from '../../lib/toast';
import PropTypes from 'prop-types';
import { HTTP } from '../../lib/http';

const SuggestionTitle = styled.div`
  color: ${(props) => (props.selected ? props.theme.colors.tfpRed : props.theme.colors.tfpBlack)};
  border-radius: 5px;
  min-width: 40%;
  cursor: pointer;
`;

const SuggestionContainer = styled.div`
  display: flex;
  max-height: 60%;
`;

const SuggestionHelp = styled.div`
  color: ${(props) => props.theme.colors.tfpBlack};
  white-space: pre-wrap;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
`;
export function CommandAutoSuggest({ command, setCommand, sendCommand, shouldRenderSuggestions }) {
  const [allowedCommands, setAllowedCommands] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    setSelectedSuggestion(0);
  }, [command]);

  const autoSuggestTheme = {
    suggestionsContainerOpen: {
      backgroundColor: theme.colors.tfpLightGrey,
      position: 'absolute',
      bottom: '10%',
      width: '80%',
      height: 'auto',
      borderRadius: '5px',
      overflowY: 'scroll',
      overflowX: 'wrap',
    },
    input: {
      width: '100%',
      padding: '1rem',
      borderRadius: '5px',
      backgroundColor: theme.colors.tfpLightGrey,
      color: theme.colors.tfpBlack,
      border: 'none',
      lineHeight: '1rem',
      outline: 'none',
    },
    suggestionsList: {
      listStyle: 'none',
      textAlign: 'left',
    },
    /*     inputOpen: 'react-autosuggest__input--open',
        inputFocused: 'react-autosuggest__input--focused',
        suggestionsContainer: 'react-autosuggest__suggestions-container',
        suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
        suggestionsList: 'react-autosuggest__suggestions-list',
        suggestion: 'react-autosuggest__suggestion',
        suggestionFirst: 'react-autosuggest__suggestion--first',
        suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
        sectionContainer: 'react-autosuggest__section-container',
        sectionContainerFirst: 'react-autosuggest__section-container--first',
        sectionTitle: 'react-autosuggest__section-title', */
  };

  function renderSuggestionsContainer({ containerProps, children, query }) {
    if (children) {
      return (
        <SuggestionContainer {...containerProps}>
          <SuggestionTitle>{children}</SuggestionTitle>
          <SuggestionHelp>{suggestions[selectedSuggestion]?.help}</SuggestionHelp>
        </SuggestionContainer>
      );
    } else {
      return null;
    }
  }

  function getCommandSuggestion(input) {
    if (!allowedCommands) return [];
    if (!input) return [];

    if (input.includes(' ')) {
      input = input.split(' ')[0];
    }

    const suggestions = allowedCommands.filter((command) => command.command.includes(input) || command.overloads.join(' ').includes(input));

    const first = suggestions.slice(0, 20);
    if (suggestions.length > 20) {
      first.push({ command: '...', help: 'More suggestions are available, narrow your search to see them', overloads: [] });
      return first;
    }

    return first;
  }

  function getSuggestionValue(suggestion) {
    return suggestion.command;
  }

  function fillSuggestion(suggestion) {
    setCommand(suggestion.command);
    setSelectedSuggestion(0);
    onSuggestionsFetchRequested({ value: suggestion.command });
  }

  function renderSuggestion(suggestion) {
    if (!suggestion) return null;

    const idx = suggestions.indexOf(suggestion);
    const overloads = suggestion.overloads.filter((overload) => overload !== suggestion.command).join(' ');

    if (idx === selectedSuggestion) {
      return (
        <>
          <SuggestionTitle
            selected={true}
            onClick={() => {
              fillSuggestion(suggestion);
            }}
            onMouseEnter={() => {
              setSelectedSuggestion(idx);
            }}
          >
            {suggestion.command} {overloads.length ? overloads : ''}
          </SuggestionTitle>
        </>
      );
    }

    return (
      <SuggestionTitle
        onClick={() => {
          fillSuggestion(suggestion);
        }}
        onMouseEnter={() => {
          setSelectedSuggestion(idx);
        }}
      >
        {suggestion.command} {overloads.length ? overloads : ''}
      </SuggestionTitle>
    );
  }

  function onSuggestionsFetchRequested({ value }) {
    setSuggestions(getCommandSuggestion(value));
  }

  function onSuggestionsClearRequested() {
    setSuggestions([]);
  }

  function onInput(e) {
    if (e.target.value || e.target.value === '') {
      setCommand(e.target.value);
    }
  }

  function onSuggestionKeyDown(e) {
    if (e.key === 'Enter') {
      sendCommand();
      e.preventDefault();
    }

    if (e.key === 'ArrowDown') {
      const newSuggestion = selectedSuggestion + 1;
      if (newSuggestion < suggestions.length) {
        setSelectedSuggestion(newSuggestion);
      } else {
        setSelectedSuggestion(0);
      }
      e.preventDefault();
    }

    if (e.key === 'ArrowUp') {
      const newSuggestion = selectedSuggestion - 1;
      if (newSuggestion >= 0) {
        setSelectedSuggestion(newSuggestion);
      } else {
        setSelectedSuggestion(suggestions.length - 1);
      }
      e.preventDefault();
    }

    if (e.key === 'Tab') {
      e.preventDefault();
      if (suggestions[selectedSuggestion].command === '...') {
        return;
      }
      fillSuggestion(suggestions[selectedSuggestion]);
    }
  }

  useEffect(() => {
    async function fetchCommands() {
      try {
        const res = await HTTP.get('/api/command');
        setAllowedCommands(res.commands);
      } catch (error) {
        console.error(error);
        sendErrorToast('Error loading command suggestions');
      }
    }
    fetchCommands();
  }, []);

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={(data) => onSuggestionsFetchRequested(data)}
      onSuggestionsClearRequested={() => onSuggestionsClearRequested()}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      theme={autoSuggestTheme}
      shouldRenderSuggestions={shouldRenderSuggestions}
      inputProps={{
        onChange: onInput,
        onKeyDown: onSuggestionKeyDown,
        value: command,
      }}
      containerProps={{
        style: {
          width: '80%',
          flexGrow: 1,
        },
      }}
      renderSuggestionsContainer={renderSuggestionsContainer}
    />
  );
}

CommandAutoSuggest.propTypes = {
  command: PropTypes.string,
  setCommand: PropTypes.func,
  onKeyDown: PropTypes.func,
  sendCommand: PropTypes.func,
  shouldRenderSuggestions: PropTypes.func,
};
