import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import { tableColumnTypes } from './types';
import { Loading } from '../loading';
import { DeleteDialog } from './deleteDialog';
import { FormDialog } from './formDialog';
import styled from 'styled-components/macro';

const caseInsensitiveComparator = (valueA, valueB) => {
  if (!valueA || !valueB) return 0;
  return String(valueA).toLowerCase().localeCompare(String(valueB).toLowerCase());
};

export const numberSort = (a, b) => {
  return a - b;
};

export const booleanCellRenderer = (param) => {
  return param.value ? '🟢' : '🔴';
};

export const defaultColumDef = {
  flex: 1,
  wrapText: true,
  autoHeight: true,
  resizable: true,
  sortable: true,
  cellStyle: { textAlign: 'left' },
  comparator: caseInsensitiveComparator,
};

const StyledControlContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  gap: 1rem;
`;

export function Table(userOptions = {}) {
  const defaultOptions = {
    theme: 'ag-theme-balham-dark',
    enableCellTextSelection: true,
    suppressCellFocus: true,
    enableRangeSelection: false,
    height: '100%',
  };
  const options = { ...defaultOptions, ...userOptions };

  const [, setGridApi] = useState(null);
  const [, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    if (options.onGridReady) {
      options.onGridReady(params);
    }
  };

  if (options.loading) return <Loading />;

  return (
    <div className={options.theme} style={{ height: options.height, width: '100%' }}>
      <AgGridReact
        rowData={options.rowData}
        columnDefs={options.columnDef}
        defaultColDef={defaultColumDef}
        columnTypes={tableColumnTypes}
        alwaysShowHorizontalScroll
        onGridReady={onGridReady}
        {...options}
      ></AgGridReact>
    </div>
  );
}

Table.propTypes = {
  theme: PropTypes.string,
  rowData: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnDef: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  height: PropTypes.string,
};

export function EditableTable(userOptions = {}) {
  const defaultOptions = {
    theme: 'ag-theme-balham-dark',
  };

  const options = { ...defaultOptions, ...userOptions };
  const [colDef, setColDef] = useState([]);

  useEffect(() => {
    if (options.deleteRowFn && options.canDeleteRows) {
      options.columnDef.push({
        headerName: '',
        flex: 0.3,
        cellStyle: { textAlign: 'center' },

        cellRenderer: (cell) => {
          return <DeleteDialog deleteRowFn={options.deleteRowFn} data={cell.data} />;
        },
      });
    }

    if (options.editRowFn && options.canEditRows) {
      options.columnDef.push({
        headerName: '',
        flex: 0.3,
        cellStyle: { textAlign: 'center' },
        cellRenderer: (cell) => {
          return (
            <FormDialog
              icon={'✏️'}
              title={'Edit'}
              mutateFn={options.editRowFn}
              data={cell.data}
              form={options.editForm}
              setDefaultValues={options.setDefaultValues}
            />
          );
        },
      });
    }

    setColDef(options.columnDef);
  }, []);

  if (options.loading) return <Loading />;

  return (
    <>
      {options.canCreateRows && options.createRowFn && (
        <StyledControlContainer>
          <FormDialog
            mutateFn={options.createRowFn}
            title={'Create'}
            icon={'➕ Create'}
            data={[]}
            form={options.editForm}
            setDefaultValues={() => {}}
          />
        </StyledControlContainer>
      )}

      <Table
        rowData={options.rowData}
        defaultColDef={defaultColumDef}
        onCellValueChanged={options.editRowFn}
        {...options}
        columnDef={colDef}
      ></Table>
    </>
  );
}

EditableTable.propTypes = {
  theme: PropTypes.string,
  rowData: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnDef: PropTypes.arrayOf(PropTypes.object).isRequired,

  canCreateRows: PropTypes.bool,
  createRowFn: PropTypes.func.isRequired,

  canDeleteRows: PropTypes.bool,
  deleteRowFn: PropTypes.func.isRequired,

  canEditRows: PropTypes.bool,
  editRowFn: PropTypes.func.isRequired,
  setDefaultValues: PropTypes.func.isRequired,

  reloadFn: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  height: PropTypes.string,
};
