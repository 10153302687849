import { EditableTable } from '../table';
import { useMutation, useQuery } from 'react-query';
import { StyledFormItem, FormLabel, FormInput } from './forms/style';
import { TfpForm } from './forms/base';
import { useForm } from 'react-hook-form';
import { FormAndTableContainer } from './style';
import { HTTP } from '../../lib/http';
import { PERMISSIONS, useAuth } from '../auth';

export function ApiTokens() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { checkPermission } = useAuth();

  const canEditRows = checkPermission({ module: PERMISSIONS['webapi.WebApiTokens'], method: 'POST' });
  const canDeleteRows = checkPermission({ module: PERMISSIONS['webapi.WebApiTokens'], method: 'DELETE' });

  const columnDefApiTokens = [
    { field: 'name', filter: 'agTextColumnFilter', sort: 'asc' },
    { field: 'secret', filter: 'agTextColumnFilter' },
    {
      field: 'permissionLevel',
      filter: 'agNumberColumnFilter',
      headerName: 'Permission Level',
      flex: 0.5,
    },
  ];

  const { isLoading, data, refetch } = useQuery('apiTokens', () => HTTP.get('/api/webapitokens'));

  const { mutate } = useMutation(
    'createApiToken',
    (data) =>
      HTTP.post(`/api/webapitokens/${data.name}`, {
        secret: data.secret,
        permissionLevel: parseInt(data.permissionLevel, 10),
      }),
    {
      onSettled: () => {
        refetch();
      },
    }
  );

  const { mutate: delMutate } = useMutation('deleteApiToken', (data) => HTTP.delete(`/api/webapitokens/${data.name}`), {
    onSettled: () => {
      refetch();
    },
  });

  const handleEdit = (data) => {
    mutate(data);
  };

  const handleDelete = (data) => {
    delMutate(data);
  };

  const setDefaultValues = (data) => {
    setValue('name', data.name);
    setValue('secret', data.secret);
    setValue('permissionLevel', data.permissionLevel);
  };

  const Form = (
    <TfpForm errors={errors} isLoading={isLoading}>
      <StyledFormItem>
        <FormLabel htmlFor="name">API Token Name</FormLabel>
        <FormInput id="name" {...register('name', { required: true })} />
      </StyledFormItem>
      <StyledFormItem>
        <FormLabel htmlFor="secret">Secret</FormLabel>
        <FormInput id="secret" {...register('secret', { required: true })} />
      </StyledFormItem>
      <StyledFormItem>
        <FormLabel htmlFor="permissionLevel">Permission Level</FormLabel>
        <FormInput id="permissionLevel" type="number" {...register('permissionLevel', { required: true })} />
      </StyledFormItem>
    </TfpForm>
  );

  return (
    <FormAndTableContainer>
      {data && (
        <EditableTable
          columnDef={columnDefApiTokens}
          reloadFn={refetch}
          rowData={data}
          loading={isLoading}
          canDeleteRows={canDeleteRows}
          deleteRowFn={handleDelete}
          editRowFn={handleSubmit(handleEdit)}
          createRowFn={handleSubmit(handleEdit)}
          editForm={Form}
          setDefaultValues={setDefaultValues}
          canEditRows={canEditRows}
          canCreateRows={canEditRows}
        />
      )}
    </FormAndTableContainer>
  );
}
