import { useMap, Rectangle, Tooltip, LayerGroup } from 'react-leaflet';
import L from 'leaflet';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const MARGIN = 1;

function CoordToRegion(min, max, mapInfo) {
  const x = Math.floor(min.lat / mapInfo.regionSize);
  const y = Math.floor(max.lng / mapInfo.regionSize);

  return L.latLng(x, y);
}

function formatRegionFileName(latlng) {
  return `r.${latlng.lat}.${latlng.lng}.7rg`;
}

export function RegionsLayer({ mapInfo, mapSize }) {
  const map = useMap();
  const [rectangles, setRectangles] = useState([]);

  const updateRegions = () => {
    let mapBounds = map.getBounds().pad(MARGIN);

    const mapMaxBounds = L.latLngBounds(
      map.unproject([-mapSize.x / 2, -mapSize.z / 2], mapInfo.maxZoom), // Southwest
      map.unproject([mapSize.x / 2, mapSize.z / 2], mapInfo.maxZoom) // Northeast
    );
    mapBounds = mapBounds.overlaps(mapMaxBounds) ? mapBounds : mapMaxBounds;

    const mapMin = mapBounds.getSouthWest();
    const mapMax = mapBounds.getNorthEast();

    const tileSize = mapInfo.regionSize;

    const startChunkX = Math.trunc(mapMin.lng / tileSize);
    const startChunkY = Math.trunc(mapMin.lat / tileSize);
    const endChunkX = Math.ceil(mapMax.lng / tileSize);
    const endChunkY = Math.ceil(mapMax.lat / tileSize);

    const newRectangles = [];

    for (let chunkX = startChunkX; chunkX <= endChunkX; chunkX++) {
      for (let chunkY = startChunkY; chunkY <= endChunkY; chunkY++) {
        const rectMin = L.point(chunkX * tileSize, chunkY * tileSize);
        const rectMax = L.point((chunkX + 1) * tileSize, (chunkY + 1) * tileSize);

        const boundsMin = map.unproject(rectMin, mapInfo.maxZoom);
        const boundsMax = map.unproject(rectMax, mapInfo.maxZoom);

        if (mapMaxBounds.contains(boundsMin) && mapMaxBounds.contains(boundsMax)) {
          newRectangles.push(
            <Rectangle bounds={[boundsMin, boundsMax]} key={`${chunkX}-${chunkY}`} color="gray" stroke={true} fill={true} weight={1}>
              <Tooltip>{formatRegionFileName(CoordToRegion(boundsMin, boundsMax, mapInfo))}</Tooltip>
            </Rectangle>
          );
        }
      }
    }

    setRectangles(newRectangles);
  };

  useEffect(() => {
    updateRegions();
  }, []);

  return <LayerGroup name={'regions'}>{rectangles}</LayerGroup>;
}

RegionsLayer.propTypes = {
  mapInfo: PropTypes.object.isRequired,
  mapSize: PropTypes.object.isRequired,
};
