import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const ButtonContainer = styled.div`
  background-color: ${(props) => props.theme.colors.tfpDarkGrey};
  color: ${(props) => props.theme.colors.tfpWhite};
  border-radius: 5px;
  padding: 0.5rem;
  width: fit-content;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

export function Button(props) {
  return <ButtonContainer onClick={props.onClick}>{props.text}</ButtonContainer>;
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
