import { useRef } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components/macro';

import PropTypes from 'prop-types';
import { ConsoleLine } from './consoleLine';

const StyledConsoleWindow = styled.div`
  background-color: ${(props) => props.theme.colors.tfpDarkGrey40};
  height: 100%;
  min-width: 100%;
  border-radius: 5px;
  text-align: left;
  padding-left: 1rem;
  overflow-y: scroll;
  flex-grow: 1;
  font-family: monospace;
`;

export function ConsoleWindow({ messages }) {
  const bottomRef = useRef(null);

  useEffect(() => {
    // scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView();
  }, [messages]);

  return (
    <StyledConsoleWindow>
      {messages.map((message, index) => {
        return <ConsoleLine key={`console-line-${index}`} message={message} />;
      })}
      <div ref={bottomRef}></div>
    </StyledConsoleWindow>
  );
}

ConsoleWindow.propTypes = {
  messages: PropTypes.array,
};
