import { EditableTable, numberSort } from '../table';
import { useMutation, useQuery } from 'react-query';
import { StyledFormItem, FormLabel, FormInput } from './forms/style';
import { TfpForm } from './forms/base';
import { useForm } from 'react-hook-form';
import { PERMISSIONS } from '../auth';
import { FormAndTableContainer } from './style';
import { HTTP } from '../../lib/http';
import { useAuth } from '../auth';

export function UserPermissions() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { checkPermission } = useAuth();

  const canEditRows = checkPermission({ module: PERMISSIONS['webapi.UserPermissions'], method: 'POST' });
  const canDeleteRows = checkPermission({ module: PERMISSIONS['webapi.UserPermissions'], method: 'DELETE' });

  const columnDefUsers = [
    { field: 'userId.combinedString', filter: 'agTextColumnFilter', headerName: 'ID' },
    { field: 'permissionLevel', filter: 'agNumberColumnFilter', headerName: 'Level', sort: 'asc', comparator: numberSort },
    { field: 'name', filter: 'agTextColumnFilter', flex: 2 },
  ];

  const { isLoading, data, refetch } = useQuery('userPermissions', () => HTTP.get('/api/userpermissions'));

  const { mutate } = useMutation(
    'createUserPermission',
    (data) =>
      HTTP.post(`/api/userpermissions/user/${data.userId}`, {
        permissionLevel: parseInt(data.permissionLevel, 10),
        name: data.name,
      }),
    {
      onSettled: () => {
        refetch();
      },
    }
  );

  const { mutate: delMutate } = useMutation('deleteUserPermission', (data) => HTTP.delete(`/api/userpermissions/user/${data.userId}`), {
    onSettled: () => {
      refetch();
    },
  });

  const handleEdit = (data) => {
    mutate({
      ...data,
      userId: data.userId,
    });
  };

  const handleDelete = (data) => {
    delMutate({
      userId: data.userId.combinedString,
    });
  };

  const setDefaultValues = (data) => {
    setValue('name', data.name);
    setValue('userId', data.userId.combinedString);
    setValue('permissionLevel', data.permissionLevel);
  };

  const Form = (
    <TfpForm errors={errors} isLoading={isLoading}>
      <p>User permissions control what users can do in the system</p>

      <StyledFormItem>
        <FormLabel htmlFor="name">Name</FormLabel>
        <FormInput id="name" {...register('name', { required: true })} />
      </StyledFormItem>
      <StyledFormItem>
        <FormLabel htmlFor="userId">User ID</FormLabel>
        <FormInput id="userId" {...register('userId', { required: true })} />
      </StyledFormItem>
      <StyledFormItem>
        <FormLabel htmlFor="permissionLevel">Permission Level</FormLabel>
        <FormInput id="permissionLevel" type="number" {...register('permissionLevel', { required: true })} />
      </StyledFormItem>
    </TfpForm>
  );

  return (
    <>
      <FormAndTableContainer>
        {data && (
          <EditableTable
            columnDef={columnDefUsers}
            reloadFn={refetch}
            rowData={data.users}
            loading={isLoading}
            deleteRowFn={handleDelete}
            canDeleteRows={canDeleteRows}
            editRowFn={handleSubmit(handleEdit)}
            createRowFn={handleSubmit(handleEdit)}
            editForm={Form}
            setDefaultValues={setDefaultValues}
            canEditRows={canEditRows}
            canCreateRows={canEditRows}
          />
        )}
      </FormAndTableContainer>
    </>
  );
}
