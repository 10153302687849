import React from 'react';
import { StyledForm, FormError, FormErrorList, FormTail } from './style';
import PropTypes from 'prop-types';

TfpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  errors: PropTypes.object,
  isLoading: PropTypes.bool,
  columns: PropTypes.number,
};

FormErrors.propTypes = {
  errors: PropTypes.object,
};

export function FormErrors({ errors }) {
  if (!errors) return null;

  const parsedFormErrors = Object.entries(errors).map(([key, value]) => {
    return value.message === '' ? `${key} is required` : value.message;
  });

  if (!parsedFormErrors.length) return null;

  return (
    <FormErrorList>
      {parsedFormErrors.map((error) => (
        <FormError key={error}>{error}</FormError>
      ))}
    </FormErrorList>
  );
}

export function TfpForm({ handleSubmit, children, errors = {}, columns = 3 }) {
  return (
    <StyledForm columns={columns} onSubmit={handleSubmit}>
      {children}
      <FormTail>
        <FormErrors errors={errors} />
      </FormTail>
    </StyledForm>
  );
}
