import Modal from 'react-modal';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import styled from 'styled-components/macro';
import { LoginForm } from './loginForm';
import { HTTP } from '../../lib/http';

Modal.setAppElement('#root');

const Container = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrorMessage = styled.p`
  color: ${(props) => props.theme.colors.tfpRed};
  font-size: 14px;
  margin-top: 10px;
`;

export function LoginModal({ OpenComponent, modalIsOpen, setIsModalOpen }) {
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState(null);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: theme.colors.tfpDarkGrey80,
      color: theme.colors.tfpWhite,
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: theme.colors.tfpDarkGrey60,
    },
  };

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function onSubmitForm(e, username, password) {
    e.preventDefault();
    HTTP.login(username, password)
      .then((data) => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      })
      .catch((err) => {
        console.error(err);
        setErrorMessage(err.response.data);
      });
  }

  return (
    <>
      <div onClick={openModal}>{OpenComponent}</div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <Container>
          <p>If you do not have an account yet, you can create one by executing the &quot;createwebuser&quot; command ingame</p>
        </Container>
        <hr />
        <LoginForm onSubmit={onSubmitForm} />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Modal>
    </>
  );
}

LoginModal.propTypes = {
  OpenComponent: PropTypes.element.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};
