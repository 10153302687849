import { EditableTable, booleanCellRenderer } from '../table';
import { useMutation, useQuery } from 'react-query';
import { StyledFormItem, FormLabel, FormInput, FormSelect, FormOption } from './forms/style';
import { TfpForm } from './forms/base';
import { useForm } from 'react-hook-form';
import { FormAndTableContainer } from './style';
import { HTTP } from '../../lib/http';
import { sendErrorToast } from '../../lib/toast';
import { PERMISSIONS, useAuth } from '../auth';

export function WebPermissions() {
  const { checkPermission } = useAuth();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const canEditRows = checkPermission({ module: PERMISSIONS['webapi.WebModules'], method: 'POST' });
  const canDeleteRows = checkPermission({ module: PERMISSIONS['webapi.WebModules'], method: 'DELETE' });

  const columnDefWebPermissions = [
    { field: 'module', filter: 'agTextColumnFilter', flex: 2, sort: 'asc' },
    {
      field: 'permissionLevelGlobal',
      filter: 'agNumberColumnFilter',
      headerName: 'Global',
      flex: 0.5,
    },
    {
      field: 'permissionLevelPerMethod.GET',
      filter: 'agNumberColumnFilter',
      headerName: 'Read',
      flex: 0.5,
    },
    {
      field: 'permissionLevelPerMethod.POST',
      filter: 'agNumberColumnFilter',
      headerName: 'Create',
      flex: 0.5,
    },
    {
      field: 'permissionLevelPerMethod.PUT',
      filter: 'agNumberColumnFilter',
      headerName: 'Edit',
      flex: 0.5,
    },
    {
      field: 'permissionLevelPerMethod.DELETE',
      filter: 'agNumberColumnFilter',
      headerName: 'Delete',
      flex: 0.5,
    },
    { field: 'isDefault', headerName: 'Default', filter: 'agBooleanColumnFilter', cellRenderer: booleanCellRenderer, flex: 0.5 },
  ];

  const { isLoading, data, refetch } = useQuery('webmodules', () => HTTP.get('/api/webmodules'));

  const { mutate } = useMutation(
    'createWebPermission',
    (data) => {
      if (data.permissionLevel !== 'inherit') data.permissionLevel = parseInt(data.permissionLevel, 10);

      if (data.permissionLevel !== 'inherit' && (!Number.isInteger(data.permissionLevel) || data.permissionLevel < 0)) {
        sendErrorToast('Permission level must be a positive integer, 0 or "inherit".');
        return;
      }

      if (data.permission === 'global' && data.permissionLevel === 'inherit') {
        sendErrorToast('Global permission level cannot be "inherit".');
        return;
      }

      const body = {};

      if (data.permission === 'global') {
        body.permissionLevelGlobal = data.permissionLevel;
      } else {
        body.permissionLevelPerMethod = {
          [data.permission]: data.permissionLevel,
        };
      }

      return HTTP.post(`/api/webmodules/${data.module}`, body);
    },

    {
      onSettled: () => {
        refetch();
      },
    }
  );

  const { mutate: delMutate } = useMutation('deleteWebMOdule', (data) => HTTP.delete(`/api/webmodules/${data.module}`), {
    onSettled: () => {
      refetch();
    },
  });

  const handleEdit = (data) => {
    mutate(data);
  };

  const handleDelete = (data) => {
    delMutate(data);
  };

  const setDefaultValues = (data) => {
    setValue('module', data.module);
    setValue('permission', data.permission);
    setValue('permissionLevel', data.permissionLevelGlobal);
  };

  const Form = (
    <TfpForm columns={3} errors={errors} isLoading={isLoading}>
      <p>
        These permissions control what web modules can do in the system. Each module has a global permission level and per-method permission
        levels.
      </p>
      <StyledFormItem>
        <FormLabel htmlFor="module">Module</FormLabel>
        <FormSelect {...register('module', { required: true })}>
          {data &&
            data.sort((a, b) => a.module > b.module).map((mod) => <FormOption key={mod.module} value={mod.module} label={mod.module} />)}
        </FormSelect>
      </StyledFormItem>
      <StyledFormItem>
        <FormLabel htmlFor="permission">Permission</FormLabel>
        <FormSelect {...register('permission', { required: true })}>
          <FormOption selected value={'global'} label="Global" />
          <FormOption value={'GET'} label="Read" />
          <FormOption value={'POST'} label="Create" />
          <FormOption value={'PUT'} label="Edit" />
          <FormOption value={'DELETE'} label="Delete" />
        </FormSelect>
      </StyledFormItem>
      <StyledFormItem>
        <FormLabel htmlFor="permissionLevel">Permission Level</FormLabel>
        <FormInput id="permissionLevel" {...register('permissionLevel', { required: true })} />
      </StyledFormItem>
    </TfpForm>
  );

  return (
    <FormAndTableContainer>
      {data && (
        <EditableTable
          columnDef={columnDefWebPermissions}
          reloadFn={refetch}
          rowData={data}
          loading={isLoading}
          canDeleteRows={canDeleteRows}
          deleteRowFn={handleDelete}
          editForm={Form}
          setDefaultValues={setDefaultValues}
          editRowFn={handleSubmit(handleEdit)}
          canEditRows={canEditRows}
        />
      )}
    </FormAndTableContainer>
  );
}
