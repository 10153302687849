export const mapInfo = {
  regionSize: 512,
  chunkSize: 16,
  tileSize: 128,
  maxZoom: 4,
};

export const defaultMapState = {
  zoom: 1,
  center: {
    lat: 0,
    lng: 0,
  },
  layers: {
    animals: true,
    hostiles: true,
    players: true,
    regions: false,
  },
};
