import styled from 'styled-components/macro';
import { useState } from 'react';
import { HTTP } from '../lib/http';
import { Button } from '../components/button';
import { useNavigate } from 'react-router-dom';
import { debounce } from '../lib/debounce';
import { useAuth } from '../components/auth';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 75%;
`;

const Heading = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
`;

const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  padding: 5px;
  width: 100%;
`;

const ExtraText = styled.p`
  font-size: 14px;
  margin-top: 20px;
  text-align: center;

  a {
    color: '#4CAF50';
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.p`
  color: ${(props) => props.theme.colors.tfpRed};
  font-size: 14px;
  margin-top: 10px;
  min-height: 20px;
  max-width: 75%;
`;

export function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const { refetch } = useAuth();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    debouncedValidateInput({ username: event.target.value, password });
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    debouncedValidateInput({ username, password: event.target.value });
  };

  const validateInput = (data) => {
    const usernameRegex = /^\w{4,16}$/;
    const passwordRegex = /^\w{4,16}$/;

    setErrorMessage(null);

    if (!usernameRegex.test(data.username)) {
      setErrorMessage('Your username must be between 4 and 16 characters long and may only contain letters, numbers, and underscores.');
      return false;
    }

    if (!passwordRegex.test(data.password)) {
      setErrorMessage('Your password must be between 4 and 16 characters long and may only contain letters, numbers, and underscores.');
      return false;
    }

    return true;
  };

  const debouncedValidateInput = debounce(validateInput, 500);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateInput({ username, password })) return;

    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');

    HTTP.registerUser(username, password, token)
      .then(() => refetch())
      .then((response) => {
        navigate('/');
      })
      .catch((error) => {
        console.error(error);
        // TODO: Translate the code to something more user friendly
        setErrorMessage(error.message ?? 'Something went wrong');
      });
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Heading>Register</Heading>
        <Label>
          Username
          <Input type="text" value={username} onChange={handleUsernameChange} />
        </Label>
        <Label>
          Password
          <Input type="password" value={password} onChange={handlePasswordChange} />
        </Label>
        <Button type="submit" text="Submit" onClick={handleSubmit} />
        <input type="submit" hidden />
        <ErrorMessage>{errorMessage ?? ''}</ErrorMessage>
        <ExtraText>
          Creating an account will inherit your 7 Days To Die game permissions. If you do not have any permissions, please contact an admin.
        </ExtraText>
      </Form>
    </Container>
  );
}
