import { NavLink } from '../components/NavLink';
import { FaHome } from 'react-icons/fa';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export function Error() {
  let { code } = useParams();
  return (
    <CenterContainer>
      <h1>Error!</h1>
      <p>{code} happened 😢</p>
      <br />
      <NavLink to="/">
        {' '}
        <FaHome /> Go home
      </NavLink>
    </CenterContainer>
  );
}
