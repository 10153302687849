import styled from 'styled-components/macro';
import versionInfo from '../version.json';

const StyledVersionBanner = styled.span`
  color: ${({ theme }) => theme.colors.tfpLightGrey};
  font-size: 0.8rem;
`;

export function VersionIndicator() {
  return (
    <StyledVersionBanner>
      <ul>
        <li>version: {versionInfo.version}</li>
        <li>commit: {versionInfo.commit}</li>
        <li>created: {versionInfo.created}</li>
      </ul>
    </StyledVersionBanner>
  );
}
