import { Dialog, DialogTrigger, DialogContent, DialogDescription, DialogHeading, DialogClose } from '../dialog/dialog';
import { Button } from '../button';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledDeleteDialog = styled(Dialog)``;

const StyledModalContent = styled(DialogContent)`
  background-color: ${(props) => props.theme.colors.tfpBlack};
  color: ${(props) => props.theme.colors.tfpWhite};
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;

  & > *:first-child {
    flex-grow: 1;
    background-color: ${(props) => props.theme.colors.tfpRed};
  }
`;

DeleteDialog.propTypes = {
  deleteRowFn: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export function DeleteDialog({ deleteRowFn, data }) {
  return (
    <StyledDeleteDialog>
      <DialogTrigger>🗑️</DialogTrigger>
      <StyledModalContent>
        <DialogHeading>Delete?</DialogHeading>
        <DialogDescription>Are you sure you want to delete this element? This action cannot be undone.</DialogDescription>
        <Row>
          <Button
            text="Delete"
            onClick={() => {
              deleteRowFn(data);
            }}
          />
          <DialogClose>Close</DialogClose>
        </Row>
      </StyledModalContent>
    </StyledDeleteDialog>
  );
}
