import { useAuth } from '.';
import PropTypes from 'prop-types';

export const HideBasedOnAuth = ({ requiredPermission, children }) => {
  const { checkPermission } = useAuth();

  if (!requiredPermission) {
    throw new Error('requiredPermission is required');
  }

  if (checkPermission(requiredPermission)) {
    return children;
  }

  return null;
};

HideBasedOnAuth.propTypes = {
  requiredPermission: PropTypes.shape({
    module: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};
