import { EditableTable } from '../table';
import { useMutation, useQuery } from 'react-query';
import { StyledFormItem, FormLabel, FormInput } from './forms/style';
import { TfpForm } from './forms/base';
import { useForm } from 'react-hook-form';
import { FormAndTableContainer } from './style';
import { HTTP } from '../../lib/http';
import { PERMISSIONS, useAuth } from '../auth';

export function Bans() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const { checkPermission } = useAuth();

  const canEditRows = checkPermission({ module: PERMISSIONS['webapi.Blacklist'], method: 'POST' });
  const canDeleteRows = checkPermission({ module: PERMISSIONS['webapi.Blacklist'], method: 'DELETE' });

  const columnDefBans = [
    { field: 'userId.combinedString', filter: 'agTextColumnFilter', headerName: 'User ID' },
    { field: 'name', filter: 'agTextColumnFilter' },
    {
      field: 'bannedUntil',
      filter: 'agDateColumnFilter',
      headerName: 'Banned Until',
      sort: 'asc',
    },
    { field: 'banReason', filter: 'agTextColumnFilter', headerName: 'Ban Reason', flex: 2 },
  ];

  const { isLoading, data, refetch } = useQuery('bans', () => HTTP.get('/api/blacklist'));

  const { mutate } = useMutation(
    'createBan',
    (data) =>
      HTTP.post(`/api/blacklist/${data.userId}`, {
        bannedUntil: data.bannedUntil,
        banReason: data.banReason,
        name: data.name,
      }),
    {
      onSettled: () => {
        reset();
        refetch();
      },
    }
  );

  const { mutate: delMutate } = useMutation('deleteBan', (data) => HTTP.delete(`/api/blacklist/${data.userId}`), {
    onSettled: () => {
      refetch();
    },
  });

  const handleEdit = (data) => {
    mutate(data);
  };

  const handleDelete = (data) => {
    delMutate({
      userId: data.userId.combinedString,
    });
  };

  const setDefaultValues = (data) => {
    setValue('name', data.name);
    setValue('userId', data.userId);
    setValue('bannedUntil', data.bannedUntil);
    setValue('banReason', data.banReason);
  };

  const Form = (
    <TfpForm errors={errors} isLoading={isLoading}>
      <StyledFormItem>
        <FormLabel htmlFor="name">Name</FormLabel>
        <FormInput id="name" {...register('name', { required: true })} />
      </StyledFormItem>
      <StyledFormItem>
        <FormLabel htmlFor="userId">User ID</FormLabel>
        <FormInput id="userId" {...register('userId', { required: true })} />
      </StyledFormItem>
      <StyledFormItem>
        <FormLabel htmlFor="bannedUntil">Banned Until</FormLabel>
        <FormInput id="bannedUntil" type="datetime-local" {...register('bannedUntil', { required: true })} />
      </StyledFormItem>
      <StyledFormItem>
        <FormLabel htmlFor="banReason">Ban Reason</FormLabel>
        <FormInput id="banReason" {...register('banReason', { required: true })} />
      </StyledFormItem>
    </TfpForm>
  );

  return (
    <FormAndTableContainer>
      {data && (
        <EditableTable
          columnDef={columnDefBans}
          reloadFn={refetch}
          rowData={data}
          loading={isLoading}
          canDeleteRows={canDeleteRows}
          deleteRowFn={handleDelete}
          editRowFn={handleSubmit(handleEdit)}
          editForm={Form}
          setDefaultValues={setDefaultValues}
          createRowFn={handleSubmit(handleEdit)}
          canEditRows={canEditRows}
          canCreateRows={canEditRows}
        />
      )}
    </FormAndTableContainer>
  );
}
