import { MapContainer, LayersControl } from 'react-leaflet';
import L, { Point, LatLng, Transformation } from 'leaflet';
import styled from 'styled-components/macro';
import { MapMods } from '../components/map/mapMods';
import { SDTDTileLayer } from '../components/map/tileLayer';
import { CoordinatesLayer } from '../components/map/coordinates';
import { GameTimeLayer } from '../components/map/gameTime';
import { AnimalLayer } from '../components/map/animals';
import { HostileLayer } from '../components/map/hostiles';
import { useEffect, useState } from 'react';
import { HTTP } from '../lib/http';
import { HideBasedOnAuth } from '../components/auth/hideBasedOnAuth';
import { PERMISSIONS } from '../components/auth';
import { PlayerLayer } from '../components/map/players';
import { RegionsLayer } from '../components/map/regions';
import useLocalStorageState from 'use-local-storage-state';
import { defaultMapState, mapInfo } from '../components/map/mapInfo';
import { StateHandler } from '../components/map/stateHandler';

import 'leaflet.zoomslider';
import 'leaflet.zoomslider/src/L.Control.Zoomslider.css';

const Container = styled.div`
  height: 100%;
  width: 100%;
  text-align: left;
`;

export function Map() {
  const [mapConfig, setMapConfig] = useState({ enabled: false, mapSize: { x: 6144, y: 255, z: 6144 } });
  const [mapState, setMapState] = useLocalStorageState('mapState', {
    defaultValue: defaultMapState,
  });

  useEffect(() => {
    const fetchMapConfig = async () => {
      const response = await HTTP.getMapConfig();
      setMapConfig(response);
    };

    fetchMapConfig();
  }, []);

  const SDTD_Projection = {
    project: (latlng) => {
      return new Point(latlng.lat / Math.pow(2, mapInfo.maxZoom), latlng.lng / Math.pow(2, mapInfo.maxZoom));
    },

    unproject: (point) => {
      return new LatLng(point.x * Math.pow(2, mapInfo.maxZoom), point.y * Math.pow(2, mapInfo.maxZoom));
    },
  };

  const SDTD_CRS = L.extend({}, L.CRS.Simple, {
    projection: SDTD_Projection,
    transformation: new Transformation(1, 0, -1, 0),

    scale: function (zoom) {
      return Math.pow(2, zoom);
    },
  });

  if (!mapConfig.enabled) {
    return <p>Map is not enabled, you can enable it via the server settings config file.</p>;
  }

  return (
    <Container>
      <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['web.map'], method: 'GET' }}>
        <MapContainer
          style={{ width: '100%', height: '100%' }}
          center={mapState.center}
          zoom={mapState.zoom}
          zoomControl={false}
          zoomsliderControl={true}
          scrollWheelZoom={true}
          attributionControl={false}
          crs={SDTD_CRS}
          maxBounds={[
            [-mapConfig.mapSize.x / 2, -mapConfig.mapSize.z / 2],
            [mapConfig.mapSize.x / 2, mapConfig.mapSize.z / 2],
          ]}
          maxBoundsViscosity={1.0}
          whenReady={(e) => {
            const { target } = e;
            target.on('overlayadd', (e) => {
              if (e.name) {
                setMapState((mapState) => ({ ...mapState, layers: { ...mapState.layers, [e.name.toLowerCase()]: true } }));
              }
            });

            target.on('overlayremove', (e) => {
              if (e.name) {
                setMapState((mapState) => ({ ...mapState, layers: { ...mapState.layers, [e.name.toLowerCase()]: false } }));
              }
            });
          }}
        >
          <StateHandler />
          <SDTDTileLayer mapInfo={mapInfo}></SDTDTileLayer>
          <CoordinatesLayer />

          <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['webapi.ServerStats'], method: 'GET' }}>
            <GameTimeLayer />
          </HideBasedOnAuth>

          <LayersControl collapsed={false} position="topright">
            <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['webapi.Animal'], method: 'GET' }}>
              <LayersControl.Overlay name="Animals" checked={mapState.layers.animals}>
                <AnimalLayer />
              </LayersControl.Overlay>
            </HideBasedOnAuth>

            <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['webapi.Player'], method: 'GET' }}>
              <LayersControl.Overlay name="Players" checked={mapState.layers.players}>
                <PlayerLayer />
              </LayersControl.Overlay>
            </HideBasedOnAuth>

            <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['webapi.Hostile'], method: 'GET' }}>
              <LayersControl.Overlay name="Hostiles" checked={mapState.layers.hostiles}>
                <HostileLayer />
              </LayersControl.Overlay>
            </HideBasedOnAuth>

            <LayersControl.Overlay name="Regions" checked={mapState.layers.regions}>
              <RegionsLayer mapInfo={mapInfo} mapSize={mapConfig.mapSize} />
            </LayersControl.Overlay>

            <MapMods />
          </LayersControl>
        </MapContainer>
      </HideBasedOnAuth>
    </Container>
  );
}
