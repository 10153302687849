import styled from 'styled-components/macro';

const Container = styled.div`
  height: 100%;
  width: 100%;
  text-align: left;
`;

export function Empty() {
  return <Container />;
}
