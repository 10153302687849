import styled from 'styled-components/macro';

/**
 * Display a form, taking up as much space as it needs
 * Under that, display a table, taking up the rest of the space
 */
export const FormAndTableContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  margin-bottom: 1rem;
  .ag-root-wrapper {
    height: 90vh;
  }
`;
