import React from 'react';
import styled from 'styled-components/macro';
import { useMods } from '../lib/mods';
import { Outlet } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { HideBasedOnAuth } from '../components/auth/hideBasedOnAuth';
import { PERMISSIONS } from '../components/auth';

const SettingsContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 250px auto;
  text-align: left;
  overflow-y: scroll;
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.tfpDarkGrey20};
  padding: 2rem;
  overflow-y: auto;
`;

const ItemContentContainer = styled.div`
  width: 90%;
  height: 80vh;
  margin: 2rem;
`;

const StyledNavLink = styled(NavLink)`
  cursor: pointer;
  color: ${(props) => (props.isActive ? props.theme.colors.tfpRed : props.theme.colors.tfpWhite)};
  text-decoration: none;
  margin-bottom: 1rem;
  transition: color 0.3s ease;

  font-size: 1.17em;
  font-weight: 500;
  line-height: 1.83em;

  &.active {
    color: ${(props) => props.theme.colors.tfpRed};
    font-weight: 700;
  }

  :hover {
    color: ${(props) => props.theme.colors.tfpRed};
  }
`;

export function Settings() {
  const { webMods } = useMods();

  const modSettings = [];

  if (webMods) {
    webMods.forEach((mod) => {
      for (const route in mod.external.settings) {
        if (Object.hasOwnProperty.call(mod.external.settings, route)) {
          modSettings.push({
            title: route,
          });
        }
      }
    });
  }

  return (
    <SettingsContainer>
      <NavContainer>
        <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['webapi.UserPermissions'], method: 'GET' }}>
          <StyledNavLink to="user-permissions">Users</StyledNavLink>
        </HideBasedOnAuth>
        <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['webapi.UserPermissions'], method: 'GET' }}>
          <StyledNavLink to="group-permissions">Groups</StyledNavLink>
        </HideBasedOnAuth>
        <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['webapi.CommandPermissions'], method: 'GET' }}>
          <StyledNavLink to="command-permissions">Commands</StyledNavLink>
        </HideBasedOnAuth>
        <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['webapi.WebModules'], method: 'GET' }}>
          <StyledNavLink to="web-permissions">Web Permissions</StyledNavLink>
        </HideBasedOnAuth>
        <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['webapi.WebUsers'], method: 'GET' }}>
          <StyledNavLink to="web-users">Web Users</StyledNavLink>
        </HideBasedOnAuth>
        <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['webapi.WebApiTokens'], method: 'GET' }}>
          <StyledNavLink to="api-tokens">Web API Tokens</StyledNavLink>
        </HideBasedOnAuth>
        <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['webapi.Blacklist'], method: 'GET' }}>
          <StyledNavLink to="bans">Blacklist</StyledNavLink>
        </HideBasedOnAuth>
        <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['webapi.Whitelist'], method: 'GET' }}>
          <StyledNavLink to="allow-list">Whitelist</StyledNavLink>
        </HideBasedOnAuth>

        {modSettings.map((mod) => (
          <StyledNavLink key={mod.title} to={mod.title.split(' ').join('-')}>
            {mod.title}
          </StyledNavLink>
        ))}
      </NavContainer>
      <ItemContentContainer>
        <Outlet />
      </ItemContentContainer>
    </SettingsContainer>
  );
}
