import { LayerGroup, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { HTTP } from '../../lib/http';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import hostileIcon from '../../img/map/icons/ui_game_symbol_zombie.png';
import { useMods } from '../../lib/mods';

function Hostile({ lat, lng, name }) {
  const { webMods } = useMods();

  const iconOverride = webMods.find((mod) => mod.external.iconOverrides && mod.external.iconOverrides.hostile);

  const icon = L.icon({
    iconUrl: iconOverride ? iconOverride.external.iconOverrides.hostile : hostileIcon,
    iconSize: [32, 32],
  });
  return (
    <Marker position={[lat, lng]} icon={icon}>
      <Popup>{name}</Popup>
    </Marker>
  );
}

Hostile.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export function HostileLayer() {
  const [hostiles, setHostiles] = useState([]);

  async function loadHostiles() {
    const hostiles = await HTTP.getHostiles();
    setHostiles(hostiles);
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      loadHostiles();
    }, 10000);

    loadHostiles();
    return () => clearInterval(interval);
  }, []);

  const allMarkers = hostiles.map((a) => <Hostile key={a.id} lat={a.position.x} lng={a.position.z} name={a.name}></Hostile>);

  return <LayerGroup name={'hostiles'}>{allMarkers}</LayerGroup>;
}
