import { FaArrowDown, FaArrowRight } from 'react-icons/fa';
import { useTheme } from 'styled-components/macro';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
const CollapseIconContainer = styled.div`
  float: left;
  margin-left: auto;
  margin-right: 1rem;
  line-height: ${(props) => (props.collapsed ? '1.1rem' : '0.5rem')};
`;

export function CollapseIcon({ collapsed, canCollapse }) {
  const theme = useTheme();

  if (!canCollapse) {
    return null;
  }

  return (
    <CollapseIconContainer>
      {collapsed ? (
        <FaArrowDown data-tip="React-tooltip" data-for="tooltip-collapse" />
      ) : (
        <FaArrowRight color={theme.colors.tfpRed} data-tip="React-tooltip" data-for="tooltip-collapse" />
      )}
    </CollapseIconContainer>
  );
}

CollapseIcon.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  canCollapse: PropTypes.bool.isRequired,
};
