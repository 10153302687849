import { EditableTable, numberSort } from '../table';
import { useMutation, useQuery } from 'react-query';
import { StyledFormItem, FormLabel, FormInput } from './forms/style';
import { TfpForm } from './forms/base';
import { useForm } from 'react-hook-form';
import { FormAndTableContainer } from './style';
import { HTTP } from '../../lib/http';
import { PERMISSIONS } from '../auth';
import { useAuth } from '../auth';

export function GroupPermissions() {
  const {
    register,
    setValue,
    formState: { errors },
    reset,
    handleSubmit,
    watch,
  } = useForm();

  const values = watch();

  const { checkPermission } = useAuth();

  const canEditRows = checkPermission({ module: PERMISSIONS['webapi.UserPermissions'], method: 'POST' });
  const canDeleteRows = checkPermission({ module: PERMISSIONS['webapi.UserPermissions'], method: 'DELETE' });

  const columnDefGroups = [
    {
      field: 'groupId',
      filter: 'agTextColumnFilter',
      headerName: 'ID',
    },
    { field: 'name', filter: 'agTextColumnFilter', flex: 2 },
    {
      field: 'permissionLevelNormal',
      filter: 'agNumberColumnFilter',
      headerName: 'Level',
    },
    {
      field: 'permissionLevelMods',
      filter: 'agNumberColumnFilter',
      headerName: 'Level mods',
      sort: 'asc',
      comparator: numberSort,
    },
  ];

  const { isLoading, data, refetch } = useQuery('groupPermissions', () => HTTP.get('/api/userpermissions'));

  const { mutate } = useMutation(
    'createGroup',
    (data) =>
      HTTP.post(`/api/userpermissions/group/${data.groupId}`, {
        name: data.name,
        permissionLevelNormal: parseInt(data.permissionLevelNormal, 10),
        permissionLevelMods: parseInt(data.permissionLevelMods, 10),
      }),
    {
      onSettled: () => {
        reset();
        refetch();
      },
    }
  );

  const { mutate: delMutate } = useMutation('deleteGroupPermission', (data) => HTTP.delete(`/api/userpermissions/group/${data.groupId}`), {
    onSettled: () => {
      refetch();
    },
  });

  const handleEdit = (data) => {
    mutate(values);
  };

  const handleDelete = (data) => {
    delMutate(data);
  };

  const setDefaultValues = (data) => {
    setValue('name', data.name);
    setValue('groupId', data.groupId);
    setValue('permissionLevelNormal', data.permissionLevelNormal);
    setValue('permissionLevelMods', data.permissionLevelMods);
  };

  const Form = (
    <TfpForm isLoading={isLoading} errors={errors}>
      <p>Group permissions do cool things!</p>
      <StyledFormItem>
        <FormLabel htmlFor="name">Group Name</FormLabel>
        <FormInput id="name" {...register('name', { required: true })} />
      </StyledFormItem>
      <StyledFormItem>
        <FormLabel htmlFor="groupId">Group ID</FormLabel>
        <FormInput id="groupId" {...register('groupId', { required: true })} />
      </StyledFormItem>
      <StyledFormItem>
        <FormLabel htmlFor="permissionLevelNormal">Permission Level (Normal)</FormLabel>
        <FormInput id="permissionLevelNormal" type="number" {...register('permissionLevelNormal', { required: true })} />
      </StyledFormItem>
      <StyledFormItem>
        <FormLabel htmlFor="permissionLevelMods">Permission Level (Mods)</FormLabel>
        <FormInput id="permissionLevelMods" type="number" {...register('permissionLevelMods', { required: true })} />
      </StyledFormItem>
    </TfpForm>
  );

  return (
    <FormAndTableContainer>
      {data && (
        <EditableTable
          columnDef={columnDefGroups}
          reloadFn={refetch}
          rowData={data.groups}
          loading={isLoading}
          canDeleteRows={canDeleteRows}
          deleteRowFn={handleDelete}
          editRowFn={handleSubmit(handleEdit)}
          editForm={Form}
          createRowFn={handleSubmit(handleEdit)}
          setDefaultValues={setDefaultValues}
          canEditRows={canEditRows}
          canCreateRows={canEditRows}
        />
      )}
    </FormAndTableContainer>
  );
}
