import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { HTTP } from '../../lib/http';
import { sendSuccessToast } from '../../lib/toast';
import { FaQuestion, FaPlay, FaPause, FaTerminal } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { CommandAutoSuggest } from './autosuggest';
import { PERMISSIONS } from '../auth';
import { HideBasedOnAuth } from '../auth/hideBasedOnAuth';
import useLocalStorageState from 'use-local-storage-state';

export const ConsoleInputField = styled.div`
  flex-grow: 1;
  padding: 1rem;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.tfpLightGrey};
  color: ${(props) => props.theme.colors.tfpBlack};
  border: none;
  line-height: 1rem;
`;

const SendButton = styled.div`
  background-color: ${(props) => props.theme.colors.tfpLightGrey};
  color: ${(props) => props.theme.colors.tfpBlack};
  width: 10%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 150%;
`;

const RoundButtonContainer = styled.div`
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.tfpLightGrey};
  color: ${(props) => props.theme.colors.tfpRed};
  min-width: 4rem;
  min-height: 4rem;
  font-size: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const FlexRow = styled.div`
  display: flex;
  min-width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2%;
  margin-right: 5%;
  > * {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

export function ConsoleInput({ addMessage, sseActive, setSseActive }) {
  const [command, setCommand] = useLocalStorageState('command', {
    defaultValue: '',
  });
  const [helpActive, setHelpActive] = useLocalStorageState('helpActiveState', {
    defaultValue: false,
  });
  async function sendCommand() {
    let executedCommand = {
      type: 'executedCommand',
      timestamp: new Date(),
      input: command,
      output: '',
    };
    try {
      const res = await HTTP.executeCommand(command);
      executedCommand.output = res.result;
      sendSuccessToast(`Command executed`);
    } catch (error) {
      console.error(error);
      let msg = 'Unknown error';
      if (error && error.response && error.response.data) {
        if (error.response.data.meta && error.response.data.meta.errorCode === 'NO_PERMISSION') {
          msg = 'You do not have permission to execute this command';
        } else if (error.response.data.meta && error.response.data.meta.errorCode === 'UNKNOWN_COMMAND') {
          msg = 'Unknown command, use "help" to see available commands';
        } else {
          msg = JSON.stringify(error.response.data, null, 2);
        }
      }
      executedCommand.output = msg;
    } finally {
      addMessage(executedCommand, false);
      setCommand('');
      setHelpActive(false);
    }
  }

  async function onClickSend(e) {
    e.preventDefault();
    sendCommand();
  }

  function onClickHelp(e) {
    e.preventDefault();
    setHelpActive(!helpActive);
  }

  function onClickSse(e) {
    e.preventDefault();
    setSseActive(!sseActive);
  }

  return (
    <FlexRow>
      <HideBasedOnAuth requiredPermission={{ module: PERMISSIONS['webapi.Command'], method: 'POST' }}>
        <CommandAutoSuggest
          command={command}
          setCommand={setCommand}
          sendCommand={sendCommand}
          shouldRenderSuggestions={() => !helpActive}
        />
        <SendButton onClick={onClickSend}>Send</SendButton>
      </HideBasedOnAuth>

      <RoundButtonContainer data-tip="React-tooltip" data-for={sseActive ? 'tooltip-pause' : 'tooltip-start'} onClick={onClickSse}>
        {sseActive ? (
          <>
            <FaPause />
            <ReactTooltip id="tooltip-pause">Pause log stream</ReactTooltip>
          </>
        ) : (
          <>
            <FaPlay />
            <ReactTooltip id="tooltip-start">Start log stream</ReactTooltip>
          </>
        )}
      </RoundButtonContainer>
      <RoundButtonContainer data-for="tooltip-help" data-tip="React-tooltip" onClick={onClickHelp}>
        {helpActive ? <FaTerminal /> : <FaQuestion />}
        {helpActive ? <ReactTooltip id="tooltip-help">Console</ReactTooltip> : <ReactTooltip id="tooltip-help">Help</ReactTooltip>}
      </RoundButtonContainer>
    </FlexRow>
  );
}

ConsoleInput.propTypes = {
  addMessage: PropTypes.func,
  sseActive: PropTypes.bool,
  setSseActive: PropTypes.func,
  helpActive: PropTypes.bool,
};
