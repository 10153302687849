import styled from 'styled-components/macro';
import { FloatingOverlay } from '@floating-ui/react';

export const StyledFloatingOverlay = styled(FloatingOverlay)`
  background: ${(props) => props.theme.colors.tfpDarkGrey40};
  display: grid;
  place-items: center;
`;

export const StyledModalContent = styled.div`
  padding: 1rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

export const StyledDialogTrigger = styled.button`
  cursor: pointer;
  color-scheme: dark;
`;
