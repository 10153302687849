import useLocalStorageState from 'use-local-storage-state';
import { useMapEvent } from 'react-leaflet';
import { defaultMapState } from './mapInfo';
import { debounce } from '../../lib/debounce';

/**
 * This is a component that renders nothing and is only used for listening to state changes.
 * This is a workaround for the fact that react-leaflet can only listen for events when it is a child of the Map component.
 */
export const StateHandler = () => {
  const [, setMapState] = useLocalStorageState('mapState', {
    defaultValue: defaultMapState,
  });

  useMapEvent(
    'move',
    debounce((e) => {
      setMapState((mapState) => ({ ...mapState, center: e.target.getCenter(), zoom: e.target.getZoom() }));
    })
  );

  return null;
};
