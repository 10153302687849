import { LayerGroup, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { HTTP } from '../../lib/http';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import animalIcon from '../../img/map/icons/ui_game_symbol_animal_tracker.png';
import rabbitIcon from '../../img/map/icons/ui_game_symbol_tracking_rabbit.png';
import bearIcon from '../../img/map/icons/ui_game_symbol_tracking_bear.png';
import chickenIcon from '../../img/map/icons/ui_game_symbol_tracking_chicken.png';
import deerIcon from '../../img/map/icons/ui_game_symbol_tracking_deer.png';
import mountainLionIcon from '../../img/map/icons/ui_game_symbol_tracking_mountain_lion.png';
import pigIcon from '../../img/map/icons/ui_game_symbol_tracking_pig.png';
import snakeIcon from '../../img/map/icons/ui_game_symbol_tracking_snake.png';
import wolfIcon from '../../img/map/icons/ui_game_symbol_tracking_wolf.png';
import { useMods } from '../../lib/mods';

const DEFAULT_ICONS = {
  animalRabbit: rabbitIcon,
  animalBear: bearIcon,
  animalChicken: chickenIcon,
  animalDeer: deerIcon,
  animalMountainLion: mountainLionIcon,
  animalPig: pigIcon,
  animalSnake: snakeIcon,
  animalWolf: wolfIcon,
  animal: animalIcon,
};

let animalIconMap = { ...DEFAULT_ICONS };

function getIcon(animalName) {
  const iconUrl = animalIconMap[animalName] || animalIconMap['animal']; // fallback to general animalIcon if not found
  return L.icon({
    iconUrl,
    iconSize: [32, 32],
  });
}

function Animal({ lat, lng, name }) {
  const icon = getIcon(name);
  return (
    <Marker position={[lat, lng]} icon={icon}>
      <Popup>{name}</Popup>
    </Marker>
  );
}

Animal.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export function AnimalLayer() {
  const [animals, setAnimals] = useState([]);
  const { webMods } = useMods();

  async function loadAnimals() {
    const animals = await HTTP.getAnimals();
    setAnimals(animals);
  }

  useEffect(() => {
    // Update the animalIconMap with overrides if available
    webMods.forEach((mod) => {
      console.log('checking mod', mod);
      if (mod.external.iconOverrides) {
        console.log('found icon overrides', mod.external.iconOverrides);
        for (const [animalName, overrideIcon] of Object.entries(mod.external.iconOverrides)) {
          console.log('setting icon override', animalName, overrideIcon);
          animalIconMap[animalName] = overrideIcon;
        }
      }
    });

    loadAnimals();
  }, [webMods]);

  useEffect(() => {
    const interval = setInterval(async () => {
      loadAnimals();
    }, 10000);

    loadAnimals();
    return () => clearInterval(interval);
  }, []);

  const allMarkers = animals.map((a) => <Animal key={a.id} lat={a.position.x} lng={a.position.z} name={a.name}></Animal>);

  return <LayerGroup name={'animals'}>{allMarkers}</LayerGroup>;
}
