import styled from 'styled-components/macro';

const dateType = {
  filter: 'agDateColumnFilter',
  cellRenderer: (param) => {
    if (!param.value) return '';
    const date = new Date(param.value);
    return date.toLocaleString();
  },
};

const RedText = styled.span`
  color: ${(props) => props.theme.colors.tfpRed};
`;

const commandType = {
  filter: 'agTextColumnFilter',
  cellRenderer: (param) => {
    if (!param.value) return '';

    if (param.data.allowed) {
      return param.value;
    } else {
      return <RedText>{param.value}</RedText>;
    }
  },
};

export const tableColumnTypes = {
  date: dateType,
  command: commandType,
};
