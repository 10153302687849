import { EditableTable, booleanCellRenderer } from '../table';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components/macro';
import { HTTP } from '../../lib/http';
import { PERMISSIONS, useAuth } from '../auth';
import { useForm } from 'react-hook-form';
import { TfpForm } from './forms/base';
import { StyledFormItem, FormLabel, FormInput } from './forms/style';

const Container = styled.div`
  height: 90vh;
`;

export function CommandPermissions() {
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { checkPermission } = useAuth();

  const canEditRows = checkPermission({ module: PERMISSIONS['webapi.CommandPermissions'], method: 'POST' });
  const canDeleteRows = checkPermission({ module: PERMISSIONS['webapi.CommandPermissions'], method: 'DELETE' });

  const columnDefCommandPermissions = [
    { field: 'command', filter: 'agTextColumnFilter', checkboxSelection: true, sort: 'asc', flex: 3 },
    {
      field: 'permissionLevel',
      filter: 'agNumberColumnFilter',
      headerName: 'Permission Level',
    },
    {
      field: 'default',
      headerName: 'Default',
      cellRenderer: booleanCellRenderer,
      flex: 0.5,
    },
  ];

  const { isLoading, data, refetch } = useQuery('commandPermissions', () => HTTP.get('/api/commandpermissions'));

  const { mutate } = useMutation(
    'createCommandPermission',
    (data) =>
      HTTP.post(`/api/commandpermissions/${data.command}`, {
        permissionLevel: parseInt(data.permissionLevel, 10),
      }),
    {
      onSettled: () => {
        refetch();
      },
    }
  );

  const { mutate: delMutate } = useMutation('deleteCommandPermission', (data) => HTTP.delete(`/api/commandpermissions/${data.command}`), {
    onSettled: () => {
      refetch();
    },
  });

  const handleEdit = (data) => {
    mutate(data);
  };

  const handleDelete = (data) => {
    delMutate(data);
  };

  const setDefaultValues = (data) => {
    setValue('command', data.command);
    setValue('permissionLevel', data.permissionLevel);
  };

  const Form = (
    <TfpForm isLoading={isLoading} errors={errors}>
      <p>Control permissions for commands</p>
      <StyledFormItem>
        <FormLabel htmlFor="command">Group command</FormLabel>
        <FormInput id="command" {...register('command', { required: true })} />
      </StyledFormItem>
      <StyledFormItem>
        <FormLabel htmlFor="permissionLevel">Permission Level</FormLabel>
        <FormInput id="permissionLevel" type="number" {...register('permissionLevel', { required: true })} />
      </StyledFormItem>
    </TfpForm>
  );

  return (
    <Container>
      {data && (
        <EditableTable
          columnDef={columnDefCommandPermissions}
          reloadFn={refetch}
          rowData={data}
          loading={isLoading}
          canDeleteRows={canDeleteRows}
          deleteRowFn={handleDelete}
          editRowFn={handleSubmit(handleEdit)}
          editForm={Form}
          setDefaultValues={setDefaultValues}
          canEditRows={canEditRows}
          canCreateRows={false}
        />
      )}
    </Container>
  );
}
