import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { useEffect } from 'react';
import { HTTP } from '../../lib/http';

export function GameTimeLayer() {
  const map = useMap();

  async function updateStats(div) {
    const data = await HTTP.get('/api/serverstats');
    var time = 'Day ' + data.gameTime.days + ', ';
    if (data.gameTime.hours < 10) time += '0';
    time += data.gameTime.hours;
    time += ':';
    if (data.gameTime.minutes < 10) time += '0';
    time += data.gameTime.minutes;
    div.innerHTML = time;
  }

  const GameTimeLayerCls = L.Control.extend({
    options: {
      position: 'bottomright',
    },

    onAdd: function (map) {
      var name = 'control-gametime',
        container = L.DomUtil.create('div', name + ' webmap-control');

      container.innerHTML = '';
      L.DomEvent.on(container, 'mousemove', L.DomEvent.stopPropagation);

      this._map = map;
      this._div = container;

      this.interval = setInterval(() => updateStats(this._div), 10000);
      updateStats(this._div);

      return container;
    },

    onRemove: function (map) {
      clearInterval(this.interval);
    },
  });

  const gameTimeLayer = new GameTimeLayerCls({});

  useEffect(() => {
    gameTimeLayer.addTo(map);
    return () => {
      map.removeLayer(gameTimeLayer);
    };
  }, []);

  return null;
}
