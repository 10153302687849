import { NavLink } from '../components/NavLink';
import { FaHome } from 'react-icons/fa';
import styled from 'styled-components/macro';

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export function NotFound() {
  return (
    <CenterContainer>
      <h1>404</h1>
      <p>You have gotten lost in the wilderness, there&apos;s nothing here!</p>
      <br />
      <NavLink to="/">
        {' '}
        <FaHome /> Go home
      </NavLink>
    </CenterContainer>
  );
}
