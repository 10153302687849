import { useMap } from 'react-leaflet';
import L from 'leaflet';
import PropTypes from 'prop-types';

export function SDTDTileLayer({ mapInfo }) {
  const map = useMap();

  const tileLayer = L.tileLayer('../../map/{z}/{x}/{y}.png?t={time}', {
    maxZoom: mapInfo.maxZoom + 1,
    minZoom: Math.max(0, mapInfo.maxZoom - 5),
    maxNativeZoom: mapInfo.maxZoom,
    minNativeZoom: 0,
    tileSize: mapInfo.tileSize,
    time: new Date().getTime(),
  });

  tileLayer.getTileUrl = function (coords) {
    coords.y = -coords.y - 1;
    return L.TileLayer.prototype.getTileUrl.bind(tileLayer)(coords);
  };

  tileLayer.addTo(map);
  return null;
}

SDTDTileLayer.propTypes = {
  mapInfo: PropTypes.object.isRequired,
};
